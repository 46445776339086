<template>
  <v-card :flat="flat">
    <v-card-text>
      <div>Enter the court numbers (or names) seperated by a comma. i.e. 6,7,8,9</div>
      <div>To enter a range of court put in the starting and ending court number sperated by a dash. i.e. 1-10 (only works with numbers)</div>
      <v-text-field
        label="Additional Courts"
        v-model="courts"
      ></v-text-field>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn
        color="error white--text"
        @click.stop="cancel"
        :disabled="saving"
      >cancel</v-btn>
      <v-btn
        color="success white--text"
        :disabled="!dirty"
        :loading="saving"
        @click.stop="save"
      >save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { firstBy } from 'thenby'
import { union } from '@/helpers/SetFunctions'
export default {
  props: ['flat', 'tournament', 'emit', 'noSort'],
  data () {
    return {
      courts: null,
      saving: false
    }
  },
  computed: {
    tCourts () {
      return (this.tournament && this.tournament.courts) || []
    },
    calcCourts () {
      if (!this.courts) return []
      let results = this.courts.split(',')
      const ranges = results.filter(f => f.includes('-'))
      ranges.forEach(r => {
        results = results.filter(f => f !== r)
        const x = r.split('-')
        let a = +x[0]
        const b = +x[1]
        if (!isNaN(a) && !isNaN(b)) {
          for (a; a <= b; a++) {
            results.push(`${a}`.trim())
          }
        }
      })

      return this.noSort ? [...new Set(results)] : [...new Set(results)].sort(firstBy(Number))
    },
    combined () {
      const all = union(new Set(this.calcCourts), new Set(this.tCourts))
      return [...all].sort(firstBy(Number)).map(m => m.trim()).filter(f => !!f)
    },
    dirty () {
      return (this.tournament && JSON.stringify(this.tCourts) !== JSON.stringify(this.combined)) || (this.emit && this.calcCourts.length > 0)
    }
  },
  methods: {
    cancel () {
      this.courts = null
      this.$emit('cancel-click')
    },
    emitCourts () {
      this.$emit('emit-courts', this.calcCourts)
    },
    save () {
      if (this.emit) {
        this.emitCourts()
        return
      }
      this.saving = true
      this.tournament.jProps.courts = this.combined
      this.tournament.saveJProps()
        .then(r => {
          this.courts = null
          this.$emit('save-complete')
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.saving = false })
    }
  },
  watch: {
    saving: function (val) {
      this.$emit('saving-change', val)
    }
  }
}
</script>
