<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{on}">
      <v-btn
        color="color3"
        text x-small
        v-on="on"
      >Add Courts</v-btn>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Add Courts</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1 color1Text--text"
          small fab
          @click.stop="dialog = false"
          :disabled="loading"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar :value="loading"></loading-bar>
      <add-courts
        v-if="dialog"
        :flat="true"
        :tournament="tournament"
        :emit="emit"
        :noSort="noSort"
        @cancel-click="dialog = false"
        @save-complete="dialog = false"
        @saving-change="onSavingChange"
        @emit-courts="emitCourts"
      ></add-courts>
    </v-card>
  </v-dialog>
</template>

<script>
import AddCourts from './AddCourts'

export default {
  props: ['tournament', 'emit', 'noSort'],
  data () {
    return {
      dialog: false,
      loading: false
    }
  },
  methods: {
    onSavingChange (v) {
      this.loading = v
    },
    emitCourts (courts) {
      this.$emit('emit-courts', courts)
      this.dialog = false
    }
  },
  components: {
    AddCourts
  }
}
</script>
